// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';

// App
import Badge from '@shared/design/components/Badge';
import Callout from '@shared/design/components/Callout';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import ProjectStatus, {ProjectStatusType} from '@shared/modules/Project/enums/ProjectStatus';
import CrewVisibleJobsForMover, {
  CrewVisibleJobsForMoverType,
} from '@shared/modules/Settings/enums/CrewVisibleJobsForMover';

const Row = Styled.View`
  flex-direction: row;
`;

const CrewVisibleJobsPanel = ({
  organization,
  isCrewVisibleJobsEditable,
}: {
  organization: OrganizationModel;
  isCrewVisibleJobsEditable: boolean;
}) => {
  const responsive = useResponsive();

  return !isCrewVisibleJobsEditable ? (
    <Callout
      text={
        'You are viewing a branch. Please contact the admins at the main branch to make changes.'
      }
    />
  ) : (
    <React.Fragment>
      <EditPanel.LabelText responsive={responsive}>Assigned Jobs</EditPanel.LabelText>
      <Space height={8} />
      <EditPanel.Text responsive={responsive}>
        {CrewVisibleJobsForMover.getLabel(
          organization.settings.crewVisibleJobsForMover as CrewVisibleJobsForMoverType,
        )}
      </EditPanel.Text>
      <Space height={16} />
      <EditPanel.LabelText responsive={responsive}>Project Statuses</EditPanel.LabelText>
      <Space height={8} />
      <Row style={{flexWrap: 'wrap'}}>
        {(organization.settings.crewVisibleJobsByProjectStatus as ProjectStatusType[]).map(
          (status, index) => (
            <React.Fragment key={index}>
              {index > 0 && <Space width={8} />}
              <Badge
                label={ProjectStatus.getStatusLabel(status)}
                color={ProjectStatus.getStatusColor(status)}
                iconLeft={ProjectStatus.getStatusIcon(status)}
                iconSize={12}
              />
            </React.Fragment>
          ),
        )}
      </Row>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewVisibleJobsPanel.fragment = gql`
  fragment CrewVisibleJobsPanel on Organization {
    id
    isPrimary
    isFranchise
    settings {
      id
      crewVisibleJobsByProjectStatus
      crewVisibleJobsForMover
    }
  }
`;

export default CrewVisibleJobsPanel;
